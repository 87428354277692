@use '@/styles/utils.scss' as *;

.amt-locales {
    position: relative;
    white-space: nowrap;

    &-label {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 1ch;
        justify-content: center;
        margin-inline: 1rem;
        padding: unset;
        position: relative;

        &:hover {
            color: var(--color-error);
        }
    }

    &-globe {
        display: block;
        height: auto;
        width: 1.5em;
    }

    &-arrow {
        display: block;
        height: auto;
        width: 0.8em;

        .amt-locales.is-open & {
            transform: rotate(180deg);
        }
    }

    &-options {
        align-content: start;
        display: grid;
        grid-template-rows: 0fr;
        margin-block-start: 1rem;
        overflow: hidden;
        transition: grid-template-rows var(--animation-timing);

        .amt-locales.is-open & {
            grid-template-rows: 1fr;
        }
    }

    &-overflow {
        min-width: max-content;
        overflow: hidden;
    }

    &-list {
        background: rgba($color-base, 0.5);
        max-height: 100%;
        min-width: max-content;
        overflow: hidden;
        padding: 1rem 0;
    }

    &-option {
        --color: var(--color-base-rgb);

        background-color: rgb(var(--color) / 20%);
        border-radius: 0;
        cursor: pointer;
        display: block;
        padding: 0.5rem 1.5rem;
        text-align: left;
        width: 100%;   

        &:hover {
            color: var(--color-error);
        }

        &.is-selected {
            background-color: rgba($color-red-1 , 20%);
            border-left: 1px solid $color-red-1;
        }
    }

    &:not(.is-inline, .is-mobile) {
        .amt-locales {
            &-options {
                align-content: end;
                bottom: calc(100% + 1rem);
                left: 50%;
                max-height: calc(100vh - 4rem);
                position: absolute;
                translate: -50% 0;
                z-index: 400;
            }

            &-list {
                background-color: var(--color-base);
                border: 1px solid rgb(var(--color-main-rgb) / 20%);
                border-radius: 5px;
            }
        }
    }

    &.is-mobile {
        width: 100%;

        .amt-locales {
            &-label {
                background-color: rgb(from var(--color-main) r g b / 20%);
                border-radius: 0;
                justify-content: space-between;
                margin-inline: 0;
                padding-block: 1em;
                padding-inline: 2.3em;
                width: 100%;

                &:hover {
                    color: unset;
                }
            }

            &-list {
                background-color: var(--color-base);
                padding: 0;
            }

            &-options {
                margin-block-start: 0;
            }

            &-option {
                padding: 1rem 1.6rem;

                &:hover {
                    background-color: rgb(from var(--color-error) r g b / 30%);
                    border-left: 1px solid var(--color-error);
                    border-radius: 0;
                    color: rgb(var(--color));
                }
            }
        }
    }
}
