@use '@/styles/utils.scss' as *;

.footer {
    align-items: center;
    background-color: $color-dark-grey-1;
    color: $color-main;
    display: flex;
    flex-direction: column;
    font-family: var(--poppins);
    font-size: 1.95rem;
    gap: 1rem;
    overflow: hidden;
    padding: 4rem 2rem;
    position: relative;

    &::before {
        aspect-ratio: 1440 / 227;
        background-image: url('/assets/images/line-bg.png');
        background-position: center center;
        background-repeat: repeat;
        background-size: cover;
        content: '';
        height: 100%;
        inset: 0;
        mix-blend-mode: lighten;
        opacity: 0.04;
        position: absolute;
        width: 100%;
    }

    > * {
        flex: 0 0 auto;
        z-index: 1;
    }

    .logosAndLinks {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 4rem;
        justify-content: space-between;
        width: 100%;

        // ul {
        //     list-style-type: none;
        //     margin: 0;
        //     padding: 0;
        // }

        // li {
        //     padding: 1rem;
        // }
    }

    .horizontalDivider {
        background-color: $color-dark-grey-4;
        display: block;
        height: 1px;
        margin: 24px auto;
        width: 100%;
    }

    .logosSection {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        gap: 1.6rem;
        justify-content: flex-start;

        .divider {
            background-color: $color-dark-grey-4;
            display: block;
            height: 56px;
            margin-right: 12px;
            width: 1px;
        }
    }

    .linksSection {
        align-self: flex-start;
        color: var(--color-main);
        display: flex;
        font-family: var(--dm-serif-display);
        gap: 2rem;
        list-style: none;
        margin: unset;
        padding: unset;

        .links {
            gap: 4rem;
        }

        a {
            text-decoration: none;
            transition: color 220ms ease-in-out;

            &:hover {
                color: var(--color-error);
            }
        }
    }

    .followUs {
        font-family: var(--dm-serif-display);

        ul {
            margin-block-start: 1rem;
        }

        li {
            background-color: #313131;
            border-radius: 50%;
            padding: 1rem;
        }

        svg {
            fill: var(--color-main);
            transition: fill 220ms ease-in-out;

            &:hover {
                fill: var(--color-error);
            }
        }
    }

    .copyrightAndLegal {
        align-items: center;
        color: var(--color-gray);
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        gap: 1.6rem;
        justify-content: space-between;
        margin-bottom: 24px;
        width: 100%;

        > * {
            width: 50%;
        }

        .links {
            justify-content: flex-end;
        }

        a {
            text-decoration: underline;
            transition: color 220ms ease-in-out;

            &:hover {
                color: var(--color-error);
            }
        }
    }

    @media (width <= $mobile-nav-breakpoint) {
        .logosAndLinks {
            flex-direction: column;
            gap: 3rem;
        }

        .linksSection {
            display: none;
        }

        .followUs {
            display: flex;
            flex-direction: column;
            font-size: 1.6rem;
            text-align: center;
        }

        .copyrightAndLegal {
            flex-direction: column;
            text-align: center;

            > * {
                width: 100%;
            }
        }

        .links {
            flex-direction: column;
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .logosAndLinks,
        .horizontalDivider,
        .copyrightAndLegal,
        .language {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .logosAndLinks,
            .horizontalDivider,
            .copyrightAndLegal,
            .language {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}

.logo,
.rating {
    display: inline-block;
    height: 6.4rem;
    width: auto;

    a,
    img {
        display: block;
        height: 6.4rem;
        width: auto;
    }
}

.socials {
    img,
    svg {
        display: block;
        height: 3rem;
        width: 3rem;
    }

    a:hover svg {
        color: var(--color-focus);
    }
}

.socials,
.sections,
.links,
.ratings {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: unset;
    padding: unset;

    > * {
        flex: 0 0 auto;
    }

    strong {
        font-weight: 900;
    }
}

.links {
    justify-self: end;
}

.copyright {
    color: $color-light-grey-3;
}

.language {
    justify-self: center;
}
