@use '@/styles/utils.scss' as *;

.main {
    background-color: $white;
    color: $black;
    grid-area: main;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0;
    width: 100%;
}

.head {
    background-color: transparent;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
